/* You can add global styles to this file, and also import other style files */
 
@import "node_modules/bootstrap/scss/bootstrap.scss";

main > .container {
    padding: 35px 0;
}

.ng-valid[required], .ng-valid.required  {
  border-left: 5px solid #42A948; /* green */
}
  
.ng-invalid:not(form)  {
   border-left: 5px solid #a94442; /* red */
}
/* Importing Bootstrap SCSS file. */
@import 'node_modules/bootstrap/scss/bootstrap';
